import axios from "axios"

const NAVIGATION_ENDPOINT_ATTRIBUTE = 'data-dynamic-navigation-endpoint';

let dynamicNavigations = document.querySelectorAll(`[${NAVIGATION_ENDPOINT_ATTRIBUTE}]`);

// Init and fill all dynamic navigations with specific data attribute
dynamicNavigations.forEach(navigation => {
    const endpointUrl = navigation.getAttribute(NAVIGATION_ENDPOINT_ATTRIBUTE);

    if (endpointUrl !== undefined) {
        axios
            .get(endpointUrl)
            .catch(error => console.log(error))
            .then(response => {
                try {
                    // Get navigation data from JSON response
                    const navigationData = response.data.root[0].navigation;

                    // Create navigation categories
                    navigationData.forEach(navCategoryData => {
                        const navCategory = createCategory(navCategoryData);

                        navigation.appendChild(navCategory);
                    });
                } catch(error) {
                    console.log(error);
                }
            });
    }
});

/**
 * Creates a navigation category
 *
 * @param  {object} Navigation data
 * @return {string} Dom node
 */
const createCategory = navCategoryData => {
    // Create navigation category column
    let navCategory = document.createElement('li');
    navCategory.setAttribute('class', 'col-md-3 col-sm-12');

    let categoryColumn = document.createElement('ul');

    // Create header
    const title   = createTitle(navCategoryData.desc, navCategoryData.url);
    const divider = createDivider();

    categoryColumn.appendChild(title);
    categoryColumn.appendChild(divider);

    // Create sub navigation items
    navCategoryData.subnavigation.forEach(subNavData => {
        const subNavigation = createNavigationItem(subNavData.desc, subNavData.url);

        categoryColumn.appendChild(subNavigation);
    })

    navCategory.appendChild(categoryColumn);

    return navCategory;
};

/**
 * Creates title dom node for navigation category
 *
 * @param  {string} Title of the category
 * @param  {string} Url target of the title
 * @return {string} Dom node
 */
const createTitle = (title, url) => {
    let navTitle = document.createElement('li');
    navTitle.setAttribute('class', 'dropdown-header');

    let link = document.createElement('a');
    let text = document.createTextNode(title);

    link.setAttribute('href', getPFinderBaseUrl() + url);
    link.appendChild(text);
    navTitle.appendChild(link);

    return navTitle;
}

/**
 * Creates a divider dom node
 *
 * @return {string} Dom node
 */
const createDivider = () => {
    let divider = document.createElement('li');

    divider.setAttribute('class', 'divider');

    return divider;
}

/**
 * Creates navigation item dom node
 *
 * @param  {string} Title of the navigation item
 * @param  {string} Url target of the navigation item
 * @return {string} Dom node
 */
const createNavigationItem = (title, url) => {
    let subNavItem      = document.createElement('li');
    let subNavItemLink  = document.createElement('a');
    let subNavItemTitle = document.createTextNode(title);

    subNavItemLink.setAttribute('href', getPFinderBaseUrl() + url);
    subNavItemLink.setAttribute('class', 'sub-menu-link');
    subNavItemLink.appendChild(subNavItemTitle);
    subNavItem.appendChild(subNavItemLink);

    return subNavItem;
}

/**
 * Gets pfinder base url for dynamic navigation links
 *
 * @return {string} PFinder base url if configured, otherwise empty string
 */
const getPFinderBaseUrl = () => {
    try {
        return window.wiloconfig.pfinderIntegration.pfinderLocation;
    } catch (error) {
        console.log('PFinder location base url is not defined in *window.wiloconfig*');

        return '';
    }
}
